<template>
  <div>
    <a-drawer title="运费模板" :visible="dialogVisible" width="1000px" centered="true" 
      @close="handleClose('ruleForm')" :destroyOnClose="true">
      <a-form-model layout="horizontal" ref="ruleForm" :model="ruleForm" :rules="rules" size="small" v-if="dialogVisible">
        <a-form-model-item label="模板名称" prop="name">
          <a-input v-model="ruleForm.name" class="withs" placeholder="请输入模板名称" />
        </a-form-model-item>
        <a-form-model-item label="计费方式" prop="type">
          <a-radio-group v-model="ruleForm.type" @change="changeRadio(ruleForm.type)">
            <a-radio :value="1">按件数</a-radio>
            <a-radio :value="2">按重量</a-radio>
            <!-- <a-radio :label="3">按体积</a-radio> -->
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="配送区域及运费" prop="region">
          <a-table :columns="columns1" :data-source="ruleForm.region" :pagination="false" 
            :rowKey="(record,index)=>{return index}" class="tempBox">
            <div slot="areaInfos" slot-scope="text, record">
              <div class="custorhand" @click="editCity(1,record)">
                <template v-if="record.iSselect">
                  <div class="text-left text-bold">全国</div>
                </template>
                <template v-else>
                  <span class="text-left" v-for="(item, index) in record.areaInfos" :key="index">
                    <!-- 如果省全选，则只展示省不展示市，如果省没有全选 市要展示 -->
                    <span v-if="item.cityNum == item.selectedNum" class="text-bold">{{ item.province + ',' }}</span>
                    <div v-else>
                      <span class="text-bold">{{ item.province }}</span>
                      <span>: {{ item.title }}</span>
                    </div>
                  </span>
                </template>
              </div>
            </div>
            <template slot="first" slot-scope="text, record">
              <a-form-model-item prop="first" class="margin-bottom-0">
                <a-input-number v-model="record.first" controls-position="right"
                  :step-strictly="ruleForm.type === 1 ? true : false"
                  :min="ruleForm.type === 1 ? 1 : 0.1" />
              </a-form-model-item>
            </template>
            <template slot="firstPrice" slot-scope="text, record">
              <a-form-model-item prop="firstPrice" class="margin-bottom-0">
                <a-input-number v-model="record.firstPrice" controls-position="right" :min="0" />
              </a-form-model-item>
            </template>
            <template slot="renewal" slot-scope="text, record" >
              <a-form-model-item prop="renewal" class="margin-bottom-0">
                <a-input-number v-model="record.renewal" controls-position="right"
                  :step-strictly="ruleForm.type === 1 ? true : false"
                  :min="ruleForm.type === 1 ? 1 : 0.1" />
              </a-form-model-item>
            </template>
            <template slot="renewalPrice" slot-scope="text, record">
              <a-form-model-item prop="renewalPrice" class="margin-bottom-0">
                <a-input-number v-model="record.renewalPrice" controls-position="right" :min="0" />
              </a-form-model-item>
            </template>
            <template slot="action" slot-scope="text, record">
              <a href="javascript:void(0)" @click="confirmEdit(ruleForm.region, record, 1)" style="color:red;">删除</a>
            </template>
          </a-table>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" size="small" @click="addCity(1)" v-show="!isSelectAllregion">
            <a-icon type="plus" />
            添加配送区域
          </a-button>
        </a-form-model-item>
        <a-form-model-item label="指定包邮" prop="appoint">
          <a-radio-group v-model="ruleForm.appoint" @change="changeDelivertype">
            <a-radio :value="true">开启</a-radio>
            <a-radio :value="false">关闭</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="ruleForm.appoint === true" prop="free">
          <a-table :columns="columns2" :data-source="ruleForm.free" :pagination="false" :rowKey="(record,index)=>{return index}" size="small">
            <template slot="areaInfos" slot-scope="text, record" >
              <!-- 如果省全选，则只展示省不展示市，如果省没有全选 市要展示 -->
              <div class="custorhand" @click="editCity(2,record)">
                <span class="text-left" v-for="(item, index) in record.areaInfos" :key="index">
                  <span v-if="item.cityNum == item.selectedNum" class="text-bold">{{ item.province + ',' }}</span>
                  <div v-else>
                    <span class="text-bold">{{ item.province }}</span>
                    <span>: {{ item.title }}</span>
                  </div>
                  <!-- <span class="text-bold">{{ item.province }}</span>: {{ item.title }} -->
                </span>
              </div>
            </template>
            <template slot="number" slot-scope="text, record" >
              <a-form-model-item class="margin-bottom-0">
                <a-input-number v-model="record.number"
                  controls-position="right"
                  :step-strictly="ruleForm.type === 1 ? true : false"
                  :min="ruleForm.type === 1 ? 1 : 0.1" />
              </a-form-model-item>
            </template>
            <template slot="price" slot-scope="text, record" >
              <a-form-model-item class="margin-bottom-0">
                <a-input-number v-model="record.price" controls-position="right" />
              </a-form-model-item>
            </template>
            <template slot="action" slot-scope="text, record" >
              <a href="javascript:void(0)" @click="confirmEdit(ruleForm.free, record, 2)" style="color:red;">删除</a>
            </template>
          </a-table>
        </a-form-model-item>
        <a-form-model-item v-if="ruleForm.appoint === true">
          <a-button type="primary" size="small" @click="addCity(2)" v-show="!isSelectAllfree">
            <a-icon type="plus" />
            添加指定包邮区域
          </a-button>
        </a-form-model-item>
        <a-form-model-item prop="sort" label="排序">
          <a-input v-model="ruleForm.sort" class="withs" placeholder="请输入排序" />
        </a-form-model-item>
      </a-form-model>
      <div class="foot-btn">
        <a-button @click="handleClose" class="margin-right-sm">取 消</a-button>
        <a-button type="primary" 
          :loading="loading" 
          @click="onsubmit('ruleForm')"
          v-hasPermi="['admin:shipping:templates:update']">确 定
        </a-button>
      </div>
    </a-drawer>
    <city ref="city" @selectCity="selectCity" :type="type" :id="curAddressId" 
      :otherSelectedArr="otherSelectedArr"
      :selectArr="selectArr" 
      :acityList="cityList">
    </city>
  </div>
</template>

<script>
import * as logistics from '@/api/logistics'
// import { Loading } from 'element-ui'
import city from './city.vue';
import { mapGetters } from 'vuex'
import { guid, resetForm } from '@/utils/ruoyi.js'
// import {Debounce} from '@/utils/validate'
const defaultRole = {
  name: '',
  type: 1,
  appoint: false,
  sort: 0,
  region: [],
  free: [],
}
const kg = '重量（kg）'
const m = '体积（m³）'
const statusMap = [
  {
    title: '首件',
    title2: '续件',
    title3: '包邮件数'
  },
  {
    title: `首件${kg}`,
    title2: `续件${kg}`,
    title3: `包邮${kg}`
  },
  {
    title: `首件${m}`,
    title2: `续件${m}`,
    title3: `包邮${m}`
  }
]
export default {
  name: 'CreatTemplates',
  components: {
    city
  },
  data() {
    return {
      loading: false,
      rules: {
        name: [
          { required: true, message: '请输入模板名称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择计费方式', trigger: 'change' }
        ],
        appoint: [
          { required: true, message: '请选择是否指定包邮', trigger: 'change' }
        ],
        region: [
          { required: true, message: '请添加配送区域', trigger: 'change' }
        ],
        free: [
          { type: 'array', required: true, message: '请至少添加一个地区', trigger: 'change' }
        ],
        number: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
      },
      rules_region: {
        first: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        firstPrice: [
          { required: true, message: '请输入运费', trigger: 'blur' }
        ],
        renewal: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        renewalPrice: [
          { required: true, message: '请输入续费', trigger: 'blur' }
        ],
      },
      nodeKey: 'city_id',
      props: {
        children: 'child',
        label: 'name',
        value: 'cityId',
        multiple: true
      },
      dialogVisible: false,
      ruleForm: Object.assign({}, defaultRole),
      listLoading: false,
      isSelectAllregion: false,
      isSelectAllfree: false,
      cityList: [],
      columns: {
        title: '首件',
        title2: '续件',
        title3: '包邮件数'
      },
      columns1: [
        {
          width: 260,
          dataIndex: 'areaInfos',
          title: '可配送区域',
          scopedSlots: { customRender: 'areaInfos' }
        }, {
          dataIndex: 'first',
          title: '首件',
          scopedSlots: { customRender: 'first' },
          width: 130
        }, {
          dataIndex: 'firstPrice',
          title: '运费（元）',
          scopedSlots: { customRender: 'firstPrice' },
          width: 120
        }, {
          dataIndex: 'renewal',
          title: '续件',
          scopedSlots: { customRender: 'renewal' },
          width: 120
        }, {
          dataIndex: 'renewalPrice',
          title: '续费（元）',
          scopedSlots: { customRender: 'renewalPrice' },
          width: 120
        }, {
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          title: '操作',
          width: 80
        }
      ],
      columns2: [
        {
          dataIndex: 'areaInfos',
          title: '选择地区',
          scopedSlots: { customRender: 'areaInfos' },
          width: 260
        },{
          dataIndex: 'number',
          title: '包邮件数',
          scopedSlots: { customRender: 'number' },
          width: 180
        },{
          dataIndex: 'price',
          scopedSlots: { customRender: 'price' },
          title: '包邮金额（元）',
          width: 120
        },{
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          title: '操作',
          width: 120
        }
      ],
      tempId: 0,
      type: 0, // 0添加 1编辑
      addressModal: false,
      indeterminate: true,
      checkAll: false,
      checkAllGroup: [],
      activeCity: -1,
      provinceAllGroup: [],
      index: -1,
      displayData: '',
      currentProvince: '',
      selectArr: [], // 传递选中的城市
      // noShippingArr: [], // 不包邮选择的城市数据
      // yesShippingArr: [], // 包邮选择的城市数据
      // noDeliveryArr: [], // 不送达选择的城市数据
      curAddressId: 0,
      otherSelectedArr: []
    }
  },
  computed: {
    ...mapGetters(['acityList', 'cityTotalNum'])
  },
  mounted() {
    this.ruleForm.appoint = true
  },
  methods: {
    /**
     * 清空文本框的值
     */
     clear() {
      this.tempId = ''
      this.isSelectAllfree = false
      this.isSelectAllregion = false
      this.loading = false
      
      // this.$refs.ruleForm.resetFields()
      defaultRole.free = []
      defaultRole.region = []
      this.ruleForm = Object.assign({}, defaultRole)
    },
    handleShow() {
      this.dialogVisible = true
    },
    handleClose(formName) {
      this.clear()
      this.dialogVisible = false
      this.loading = false
    },
    
    /**获取某个省下面的市的个数 */
    getAreaNum(cityId) {
      let num = 0
      this.acityList.forEach((item,index)=>{
        if(item.areaId == cityId) {
          num = item.children.length
        }
      })
      return num
    },
    /**
     * 获取运费模板详情
     * appoint true包邮 false不包邮
     */
    getInfo(options) {window.console.log('options',options)
      options && (this.tempId = options.id)
      // const loadingInstance = Loading.service({ fullscreen: true })
      // 根据id获取运费模板详情
      logistics.templateDetailApi({ id: this.tempId }).then(res => {window.console.log('res',res)
        if(res.success) {
          let regionList = res.data.regionList
          let freeList = res.data.freeList
          /**增加data字段，回显已经选择的城市 iSselect*/
          if(regionList.length) {
            let selectedCityNum = 0
            regionList.forEach((item,index)=>{
              if(!item.id) item.id = guid(8,10)
              item.data = []
              item.areaInfos.forEach((item1,index1)=>{
                let obj = {
                  "areaName": item.province,
                  "areaId": item.provinceId,
                  "children": []
                }
                let cityIdsArr = item1.cityIds.split(",")
                cityIdsArr.forEach((item2,index2)=>{
                  obj.children.push({
                    "areaId": item2
                  })
                })
                item1.selectedNum = cityIdsArr.length
                selectedCityNum += cityIdsArr.length
                item1.cityNum = this.getAreaNum(item1.provinceId)
                item.data.push(obj)
              })
              if(selectedCityNum == this.cityTotalNum) item.iSselect = true
            })
            this.setBtnShow(regionList, 'isSelectAllregion')
          }
          console.log('regionList111',regionList)
          if(freeList.length) {
            freeList.forEach((item,index)=>{
              if(!item.id) item.id = guid(8,10)
              item.data = []
              item.areaInfos.forEach((item1,index1)=>{
                let obj = {
                  "areaName": item.province,
                  "areaId": item.provinceId,
                  "children": []
                }
                let cityIdsArr = item1.cityIds.split(",")
                cityIdsArr.forEach((item2,index2)=>{
                  obj.children.push({
                    "areaId": item2
                  })
                })
                item1.selectedNum = cityIdsArr.length
                item1.cityNum = this.getAreaNum(item1.provinceId)
                item.data.push(obj)
              })
            })
            this.setBtnShow(freeList, 'isSelectAllfree')
          }
          this.ruleForm = Object.assign(this.ruleForm, {
            name: res.data.name,
            type: res.data.type,
            appoint: res.data.appoint,
            sort: res.data.sort,
            region: regionList,
            free: freeList
          })

          this.columns = Object.assign({}, statusMap[this.ruleForm.type - 1])
          console.log('ruleForm', this.ruleForm)
          this.changeRadio(res.data.type)
          this.dialogVisible = true
        }
      }).catch(res => {
        this.$message.error(res.message)
      })
    },
     /**
     * 切换计费方式
     */
    changeRadio(num) {window.console.log('num',num)
      // this.columns = Object.assign({}, statusMap[num - 1])
      if(num == 1) {
        this.columns1[1].title = '首件'
        this.columns1[3].title = '续件'
        this.columns2[1].title = '包邮件数'
      } else if(num == 2) {
        this.columns1[1].title = '首件重量（kg）'
        this.columns1[3].title = '续件重量（kg）'
        this.columns2[1].title = '包邮重量（kg）'
      }
    },
    /**
     * 切换指定包邮
     */
    changeDelivertype(e) {
      this.ruleForm.appoint = e.target.value
    },
    changType(type) {
      this.type = type
      this.ruleForm.type = type
    },
    /**添加配送区域
     * 显示选择城市弹窗
     */
    addCity(type) {
      this.selectArr = []
      this.type = type;
      /** 获取 已经选中的城市数组,要置灰,不可勾选 */
      this.otherSelectedArr = []
      if(type == 1) {
        this.ruleForm.region.forEach((item, index)=>{
          this.otherSelectedArr.push(...item.data) 
        })
      }else if(type == 2) {
        this.ruleForm.free.forEach((item, index)=>{
          this.otherSelectedArr.push(...item.data) 
        })
      }

      this.$refs.city.empty()
      this.$refs.city.addressModal = true;
    },
    /**
     * 编辑城市区域弹窗
     */
    editCity(type, record) {
      this.type = type;
      this.curAddressId = record.id
      this.selectArr = []
      this.selectArr = record.data
      /** 获取 除当前这条数据以外其他的选中的城市数组 */
      this.otherSelectedArr = []
      if(type == 1) {
        this.ruleForm.region.forEach((item, index)=>{
          if(item.id != record.id) {
            this.otherSelectedArr.push(...item.data) 
          }
        })
      }else if(type == 2) {
        this.ruleForm.free.forEach((item, index)=>{
          if(item.id != record.id) {
            this.otherSelectedArr.push(...item.data) 
          }
        })
      }
      
      this.$refs.city.empty()
      this.$refs.city.addressModal = true;
    },
    /**添加配送区域
     * 选择城市后处理数据
     */
    selectCity(obj) {
      window.console.log('选择城市后得到的城市数组 data', obj)
      let data = obj.selectList, type = obj.type, iSselect = obj.iSselect, editedIndex = -1

      if(obj.isEdit) {
        // 编辑 
        if(obj.type == 1) {
          editedIndex = this.ruleForm.region.findIndex(item=>{return item.id == obj.id})
        }else if(obj.type == 2){
          editedIndex = this.ruleForm.free.findIndex(item=>{return item.id == obj.id})
        }
      }
      
      let areaInfos = []
      data.forEach(item => {
        let aobj = {
          provinceId: item.areaId,
          province: item.areaName,
          title: '',
          cityIds: '',
          cityNum: item.cityNum,
          selectedNum: item.children.length
        }
        if (item.children) {
          aobj.cityIds = item.children.map(item1 => { return item1.areaId }).join(",")
          aobj.title = item.children.map(item1 => { return item1.areaName }).join(",")
        }
        areaInfos.push(aobj)
      })
      switch (type) {
        case 1:
          if(obj.isEdit) {
            // 编辑
            let flag = this.setisSelect(obj.selectList)

            this.$set(this.ruleForm.region[editedIndex], 'areaInfos', areaInfos)
            this.$set(this.ruleForm.region[editedIndex], 'data', obj.selectList)
            this.$set(this.ruleForm.region[editedIndex], 'iSselect', flag)
          }else{
            // 新增
              this.ruleForm.region.push({
              id: guid(8,10),
              iSselect: iSselect,
              areaInfos: areaInfos,
              first: 0,
              firstPrice: 0,
              renewal: 1,
              renewalPrice: 0,
              data: obj.selectList
            });
          }
          console.log('this.ruleForm.region',this.ruleForm.region)
          /**控制添加配送区域按钮显示隐藏 */
          this.setBtnShow(this.ruleForm.region, 'isSelectAllregion')
          break;
        case 2:
          if(obj.isEdit) {
            // 编辑
            this.ruleForm.free[editedIndex].areaInfos = areaInfos
            this.ruleForm.free[editedIndex].data = obj.selectList
            this.$set(this.ruleForm.free[editedIndex], 'iSselect', flag)
          }else{
            this.ruleForm.free.push({
              id: guid(8,10),
              iSselect: iSselect,
              areaInfos: areaInfos,
              number: 0,
              price: 0,
              data: obj.selectList
            });
            console.log('this.ruleForm.free',this.ruleForm.free)
          }
          this.setBtnShow(this.ruleForm.free, 'isSelectAllfree')
          break;
        case 3:
          this.noDeliveryList.push({
            place: data,
            placeName: cityName,
          });
          this.noDeliveryArr = this.noDeliveryArr.concat(data);
          break;
      }
    },
    /**
     * 控制 添加城市按钮 显示隐藏
     */
    setBtnShow(arr, fieldname) {
      let selectedNum = 0
      arr.forEach((item)=>{
        item.data.forEach((item1)=>{
          selectedNum += item1.children.length
        })
      })
      selectedNum == this.cityTotalNum ? this[fieldname] = true : this[fieldname] = false
    },
    /**
     * 计算是否是全选
     */
    setisSelect(arr) {
      let selectedNum = 0
      arr.forEach((item)=>{
        selectedNum += item.children.length
      })
      return selectedNum == this.cityTotalNum ? true : false
    },
    /**
     * 删除所选城市
     */
    confirmEdit(areaArr, record, type) {
      let delIndex = areaArr.findIndex(item=>{return item.id == record.id})
      areaArr.splice(delIndex, 1)
      let str = ''
      type == 1 ? str = 'isSelectAllregion' : str = 'isSelectAllfree'
      this.setBtnShow(areaArr, str)
    },

    onsubmit: (function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          // 删掉data字段，这个只作为前端使用，不需要给后端
          let obj = JSON.parse(JSON.stringify(this.ruleForm))
          obj.region.forEach((item,index)=>{
            delete item.data
          })
          obj.free.forEach((item,index)=>{
            delete item.data
          })
          const param = {
            appoint: this.ruleForm.appoint,
            name: this.ruleForm.name,
            sort: this.ruleForm.sort,
            type: this.ruleForm.type,
            // 配送区域及运费
            shippingTemplatesRegionRequestList: obj.region,
            // 指定包邮设置
            shippingTemplatesFreeRequestList: obj.free
          }
          
          window.console.log('param this.ruleForm', param, this.ruleForm)
          // return
          if (this.tempId) {
            param.id = this.tempId
            console.log('编辑param',param)
            logistics.shippingUpdate(param).then(res => {
              console.log('res', res)
              if (res.success) {
                this.$message.success('操作成功')
                this.handleClose()
                this.$nextTick(() => {
                  this.dialogVisible = false
                  this.ruleForm = {}
                })
                setTimeout(() => {
                  this.$emit('getList')
                }, 600)
              }else{
                this.$message.error(res.message);
              }
              this.loading = false;
            })
          } else {
            logistics.shippingSave(param).then(response => {
              console.log('res添加数据', response)
              if (response.success) {
                this.$message.success('添加成功')
                setTimeout(() => {
                  this.$emit('getList')
                  this.handleClose()
                }, 600)
                this.$nextTick(() => {
                  this.dialogVisible = false
                  this.ruleForm = {}
                })
              } else {
                this.$message.error(response.message);
              }
              this.loading = false
            })
          }
        } else {
          return false
        }
      })
    })
  }
}
</script>

<style scoped lang="less">
.withs {
  width: 50%;
}

.noBox {
  ::v-deep.a-form-model-item__content {
    margin-left: 0 !important;
  }
}

.tempBox {
  ::v-deep.a-input-number--mini {
    width: 100px !important;
  }
}

.custorhand {
  cursor: pointer;
}

.margin-bottom-0 {
  margin-bottom: 0!important;
}
</style>